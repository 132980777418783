import { Component, OnInit } from '@angular/core';
import { CommonAPIService } from 'app/utils/services/common-api.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-nav-two-layout',
  templateUrl: './nav-two-layout.component.html',
  styleUrls: ['./nav-two-layout.component.scss']
})

export class NavTwoLayoutComponent implements OnInit {

  year: number = new Date().getFullYear();
  email: string = '';
  showSuccessMessage: boolean = false;
  isInvalidEmail: boolean = false;

  constructor(private commonAPIService: CommonAPIService) { }

  ngOnInit(): void {
    //Solution dropdown code New catalog page function:
    $(document).ready(function () {
      // Add class on button click
      $(".up__solution-btn").on("click", function (event) {
        event.stopPropagation(); // Prevent click from propagating to document
        $(".up__dropdown").toggleClass("active"); // Toggle class on up__dropdown
      });

      // Remove class on clicking outside
      $(document).on("click", function () {
        $(".up__dropdown").removeClass("active");
      });

      // Remove class on clicking other buttons (if needed)
      $(".up__solution-btn").siblings().on("click", function () {
        $(".up__dropdown").removeClass("active");
      });
    });
  }

  async subscribe() {
    if (!this.email || !this.validateEmail(this.email)) {
      this.isInvalidEmail = true;
      return;
    }

    try {
      this.isInvalidEmail = false;
      const response: any = await this.commonAPIService.subscribeUser(this.email);
      
      if (response && response.status === 200) {
        this.showSuccessMessage = true;
        this.email = "";
      } else {
        this.showSuccessMessage = false;
      }
      // else {
      //   alert("Subscription failed. Please try again.");
      // }
    } catch (error) {
      console.error("Error:", error);
      alert("Subscription failed. Please try again.");
    }
  }

  validateEmail(email: string): boolean {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|io|info|co|us)$/;
    return regex.test(email);
  }

  onEmailChange(){
    // if (!this.email) {
    // }
    this.isInvalidEmail = false; // Reset only when input is empty
  }

}
